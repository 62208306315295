import { LinearProgress } from "@material-ui/core";
import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { auth, db } from "./firebase";
import Chat from "./pages/Chat/index";
import Login from "./pages/Login"

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  const [noAuth, setNoAuth] = useState(true)

  useEffect(()=>{
    db.ref('config/noAuthRequired').on('value', (snapshot)=>{
      console.log('snapshot',snapshot.val())
      setNoAuth(snapshot.val())
      auth.onAuthStateChanged((user)=>{
        if(user) {
          setLoggedIn(true)
        }
        else setLoggedIn(false)
  
        setLoading(false)
      })
    })
  },[])

  return (
    <Router>
      {loading ?
        <LinearProgress />
      :
      <>
        {(loggedIn || noAuth) ?
          <Switch>
            <Route path="/" exact component={Chat} />
            <Route path="/:matchId/:userHash" exact component={Chat} />
            <Route path="/:matchId/:userHash/:reschedule" component={Chat} />
          </Switch>
        :
          <Switch>
            <Route component={Login} />
          </Switch>
        }
      </>
      } 
    </Router>
  );
}

export default App;
